
import { defineComponent } from 'vue'
import { Form, Field, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'

import Logo from '@/assets/svg/logo.svg?inline'
import Arrow from '@/assets/svg/arrow-bottom.svg?inline'
import AlertBlock from '@/components/molecules/AlertBlock.vue'

export default defineComponent({
  name: 'RegistrationStepTwoSection',
  data () {
    const schema = yup.object().shape({
      secret_code: yup.string().required('Secret Code '),
      name: yup.string().required('Child’s First Name'),
      subscribe_to_news: yup.boolean()
    })

    return {
      schema,
      message: '',
      country: 'USA',
      place: 'Place of purchase',
      selectBody: false,
      selectCountryBody: false,
      data: {
        subscribe: false
      },
      places: {},
      countries: {},
      placeError: ''
    }
  },
  mounted () {
    this.getPlaces()
    this.getCountries()
    this.getCountryByIp()
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    Logo,
    AlertBlock,
    Arrow
  },
  methods: {
    createUser (data) {
      if (!this.place || this.place === 'Place of purchase') {
        this.placeError = 'This field is mandatory'
        return
      }

      this.placeError = ''
      data.subscribe_to_news = this.data.subscribe
      data.place_of_purchase = this.place
      data.country = this.country
      this.$store.dispatch('getPayments/createUser', data).then((res) => {
        this.$router.push('/dashboard/welcome')
      },
      (error) => {
        this.message = error.response.data.errors
      })
    },
    getPlaces () {
      this.$store.dispatch('getPayments/getPlaces').then((res) => {
        this.places = res.data.data
      },
      (error) => {
        this.message = error.response.data.errors
      })
    },
    getCountries () {
      this.$store.dispatch('getPayments/getCountries').then((res) => {
        this.countries = res.data.data
      },
      (error) => {
        this.message = error.response.data.errors
      })
    },
    getCountryByIp () {
      this.$store.dispatch('getPayments/getCountryByIp').then((res) => {
        this.country = res?.data?.data?.attributes?.name
      },
      (error) => {
        this.message = error.response.data.errors
      })
    },
    openSelect () {
      if (this.selectBody === false) {
        this.selectBody = true
        this.placeError = ''
      } else this.selectBody = false
    },
    openSelectCountry () {
      if (this.selectCountryBody === false) {
        this.selectCountryBody = true
      } else this.selectCountryBody = false
    },
    setPlace (place) {
      this.place = place
    },
    setCountryPlace (country) {
      this.country = country
    }
  }
})
